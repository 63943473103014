import { apiStatusConstants, ewsEntityTypes, formatVehicleTitle } from 'app-constants';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useContext } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import { CommonStrongText } from 'components/styledComponents';
import { vehiclesActions } from 'store/vehiclesStore';
import { CircularSpinner, MapContainer, VehicleImage } from 'components';
import { theme as themeStyles } from 'styles/appTheme';
import { Divider, Typography } from 'antd';
import { createSelector } from 'reselect';
import { ReconTag, reconTagTypes } from 'components/tags';
import DetailPageVehicleInfo from 'components/common/DetailPageVehicleInfo';
import { NEW, RETAIL_READY } from 'app-constants/reconPlanStatusTypes';
import VehicleTag from 'components/common/VehicleTag';
import { DECLINED } from 'app-constants/taskStatusTypes';
import ProfitTimeMetal from 'components/common/ProfitTimeMetal';
import { HQAndProfitTimeContext } from 'utils/contexts';

export const { Text } = Typography;
export const headersPropsSelector = createSelector(
  (state) => state.vehicles.locations,
  (state) => state.dealers.current.data,
  (_, vehicleId) => vehicleId,
  (state) => state.vehicles.vehicleProfitTime,
  (state) => state.vehicles.vehicleProfitTimeFetchStatus,
  (locations, dealer, vehicleId, vehicleProfitTime, vehicleProfitTimeFetchStatus) => {
    const locationsData = locations[vehicleId];
    return {
      fetchStatus: locationsData ? locationsData.fetchStatus : apiStatusConstants.IS_FETCHING,
      vehicleLocations: locationsData ? locationsData.locations : [],
      dealer,
      vehicleProfitTime,
      vehicleProfitTimeFetchStatus
    };
  }
);
export const Header = withLDConsumer()(({ vehicle, flags }) => {
  const vehicleTitle = formatVehicleTitle(vehicle);
  const {
    fetchStatus,
    vehicleLocations,
    dealer,
    vehicleProfitTime,
    vehicleProfitTimeFetchStatus
  } = useSelector((state) => headersPropsSelector(state, vehicle.id));
  const isEnterpriseOrContainerDealer =
    dealer.entityType === ewsEntityTypes.ENTERPRISE || dealer.entityType === ewsEntityTypes.CONTAINER;
  const showDealerName = isEnterpriseOrContainerDealer && flags?.ireconHqView;
  const anyNeedsApprovalTasks = vehicle?.tasks?.items.some(
    (task) => task.needsApproval && task.status !== DECLINED && !task.declined && !task.declinedOn
  );
  const dispatch = useDispatch();
  const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);
  const childDealersWithProfitTime =
    useSelector((state) => state.dealers?.current?.data?.childDealersWithProfitTime) || [];

  useEffect(() => {
    dispatch(vehiclesActions.getVehicleLocations(vehicle.id));
    if (hasReconProfitTime && (!flags.reconHiddenFilter || !vehicle?.hidden)) {
      if (!isHQ || (flags?.reconProfittime && childDealersWithProfitTime.includes(vehicle?.dealerId))) {
        dispatch(vehiclesActions.getVehicleProfitTime(vehicle.id, vehicle.dealerId));
      }
    }
  }, [vehicle.id, hasReconProfitTime, flags?.reconProfittime, vehicle?.dealerId, isHQ, childDealersWithProfitTime]);

  useEffect(() => {
    return () => {
      if (vehicleProfitTime && !!Object.keys(vehicleProfitTime).length) {
        //clear profit time when dismounting
        dispatch(vehiclesActions.setVehicleProfitTime({}));
        dispatch(vehiclesActions.setVehicleProfitTimeFetchStatus(apiStatusConstants.PENDING));
      }
    };
  }, []);

  const colors = [...new Set([vehicle.baseColor, vehicle.color].filter((c) => c))];

  return (
    <StyledHeaderContainer>
      <StyledImageContainer>
        <VehicleImage
          src={vehicle?.heroImageUrl}
          nonMarketingSrc={vehicle?.nonMarketingHeroImageUrl}
          fallbackSrc={vehicle?.appraisalHeroImageUrl}
          isHeroOrAppraisal
          imageQualityWidth={640} // This determines the image quality we pull back from the image api, setting it to twice the size of the box
          imageQualityHeight={356}
          imageStyle={{ minWidth: '320px', height: '178px' }}
          noImageStyle={{
            width: '320px',
            height: '178px',
            borderWidth: '0 0.5px 0.5px 0',
            borderStyle: 'solid',
            borderColor: themeStyles.colors.borderGray
          }}
        />
      </StyledImageContainer>
      <StyledDescriptionContainer reconProfitTime={hasReconProfitTime}>
        {hasReconProfitTime ? (
          <StyledNameTagContainer>
            <div>
              <StyledVehicleName reconProfitTime={hasReconProfitTime}>{vehicleTitle}</StyledVehicleName>
              {(anyNeedsApprovalTasks ||
                vehicle?.recall?.toUpperCase() === 'OPEN' ||
                vehicle?.excludedOn ||
                vehicle.reconStatus === NEW ||
                vehicle.reconStatus === RETAIL_READY ||
                vehicle?.removedOn ||
                vehicle?.sold ||
                (flags.reconHiddenFilter && vehicle?.hidden)) && (
                <StyledTagContaier>
                  <VehicleTag flags={flags} anyNeedsApprovalTasks={anyNeedsApprovalTasks} vehicle={vehicle} />
                </StyledTagContaier>
              )}
            </div>
            {vehicleProfitTimeFetchStatus === apiStatusConstants.IS_FETCHING ? (
              <CircularSpinner />
            ) : (
              (!flags.reconHiddenFilter || !vehicle?.hidden) &&
              (!isHQ || (flags?.reconProfittime && childDealersWithProfitTime.includes(vehicle?.dealerId))) && (
                <ProfitTimeMetal profitTimeScore={vehicleProfitTime} />
              )
            )}
          </StyledNameTagContainer>
        ) : (
          <StyledVehicleName reconProfitTime={hasReconProfitTime}>{vehicleTitle}</StyledVehicleName>
        )}
        {!hasReconProfitTime && <DetailPageVehicleInfo vehicle={vehicle} colors={colors} />}
        <Divider style={{ margin: hasReconProfitTime ? '13px 0px 5px 0px' : '4px 0' }} />
        {!hasReconProfitTime && <Text style={{ lineHeight: '23px' }}>{vehicle.dealerName}</Text>}
        {!hasReconProfitTime && vehicle?.excludedOn && (
          <StyledExcludedReconTag type={reconTagTypes.EXCLUDED} vehicle={vehicle} />
        )}
        {hasReconProfitTime && (
          <DetailPageVehicleInfo
            vehicle={vehicle}
            colors={colors}
            reconProfitTime={hasReconProfitTime}
            showDealerName={showDealerName}
          />
        )}
      </StyledDescriptionContainer>
      <StyledMapPlaceHolder reconProfitTime={hasReconProfitTime}>
        {fetchStatus === apiStatusConstants.IS_FETCHING ? (
          <CircularSpinner />
        ) : (
          <MapContainer vehicleLocations={vehicleLocations} />
        )}
      </StyledMapPlaceHolder>
    </StyledHeaderContainer>
  );
});

//#region Styled Components for Header
const StyledHeaderContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  min-width: 0;
`;
const StyledImageContainer = styled.div`
  position: relative;
`;

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledDescriptionContainer = styled(StyledColumnContainer)`
  margin-left: ${({ reconProfitTime }) => (reconProfitTime ? '12px' : '24px')};
  min-width: 0;
  flex: 1 1 0px;
`;
const StyledExcludedReconTag = styled(ReconTag)`
  .ant-tag& {
    width: 80px;
    margin-top: 4px;
    margin-left: 0;
  }
`;
const StyledMapPlaceHolder = styled.div.attrs({
  className: 'center-content'
})`
  width: 360px;
  margin: 0px 5px 0px ${({ reconProfitTime }) => (reconProfitTime ? '12px' : '15px')};
  border: none;
  position: relative;
`;

const StyledVehicleName = styled(CommonStrongText).attrs({
  ellipsis: true
})`
  margin-top: ${({ reconProfitTime }) => (reconProfitTime ? '0px' : '16px')};
  font-size: ${({ theme, reconProfitTime }) => (reconProfitTime ? theme.fontSizes.lg : theme.fontSizes.xlg)};
  line-height: 25px;
`;
const StyledNameTagContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
`;
const StyledTagContaier = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1 1 0px;
  align-items: center;
  margin-left: -8px;
`;
